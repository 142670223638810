import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map,catchError } from 'rxjs/operators';
import { dominio_ws } from '../../config/configuraciones_globales';
import { SettingsService } from '../settings/settings.service';
import * as logoFile from '../../../assets/images/img_js/logo.js';
import swal from 'sweetalert2';
import * as moment from 'moment';
//import * as Excel from "exceljs"
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { GadService } from '../gplan_metas/gad.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesExcelService {
  url:string=dominio_ws+'/reporte';
  
  tabla:string='Reportes Excel';
  constructor(public http:HttpClient,
              public _settingsService:SettingsService,
              public _gadService:GadService) { }

  
    cargarReporteManzanas(pk_ubigeo:number,tipo:any):Observable<any>{
      let url_ws=`${this.url}/manzanas/${pk_ubigeo}/${tipo}`;
      return this.http.get(url_ws)
      .pipe(map((resp:any) =>{
          let dato={};
          if(resp.status === 'error'){
            console.log(`Error - Service Obtener ${this.tabla}: `,resp.message,'error')
            
          }else{
            dato=resp.data.mensaje;
          }
          return dato;
        }))
        .pipe(catchError( err =>{
          swal.fire(
            `Error no controlado ${this.tabla}`,
            'Revisar Detalle en consola',
            'error'
          )
          
          console.log(`Error no controlado - Service Obtener ${this.tabla}= `+ JSON.stringify(err));
          return Observable.throw(err);
        }))
    }

    cargarReporteLotes(pk_lote:number,tipo:any):Observable<any>{
      let url_ws=`${this.url}/lotes/${pk_lote}/${tipo}`;
      return this.http.get(url_ws)
      .pipe(map((resp:any) =>{
          let dato={};
          if(resp.status === 'error'){
            console.log(`Error - Service Obtener ${this.tabla}: `,resp.message,'error')
            
          }else{
            dato=resp.data.mensaje;
          }
          return dato;
        }))
        .pipe(catchError( err =>{
          swal.fire(
            `Error no controlado ${this.tabla}`,
            'Revisar Detalle en consola',
            'error'
          )
          
          console.log(`Error no controlado - Service Obtener ${this.tabla}= `+ JSON.stringify(err));
          return Observable.throw(err);
        }))
    }

    reporteExcelManzanasTodas(listaManzanas:any, datosGad:any){
      const tituloReporte:any=datosGad.nombre;
      
     
      const cabeceraCeldas = [
        'País', 
        'Provincia',
        'Ciudad',
        'Parroquia',
        'Cod. Provincia',
        'Cod. Ciudad',
        'Cod. Parroquia',
        'Cod. Zona',
        'Cod. Sector',
        'Cod. Manzana',
        'Cantidad Lotes',
        'Área Edificada (m2)',
        'Área Edificable (m2)',
        'Nivel Ocupación (%)',
        'Área Vacante (m2)',
        'Lotes Vacantes',
        'Área Total (m2)',
        'Lotes Ocupados',
        'Área Edificada ed. (m2)',
        'Área Edificable ed. (m2)',
        'Nivel Edificabilidad ed. (%)',
        'Área Edificar ed (m2)',
        'Área Vivienda (m2)',
        'Población (hab)',
        'Índice Poblacional  (m2/hab)',
        'Actual CR (hab)',
        'Potencial CR (hab)',
        'Densidad CR (hab/Ha)'

      ];
     //const libroExcel = new Excel.Workbook();
     const libroExcel = new Workbook();
      const hojaExcel = libroExcel.addWorksheet('Manzanas');
  
      // Add Image
      const logo = libroExcel.addImage({
        base64: logoFile.logoBase64,
        extension: 'jpeg',
      });
      //Agregar imagen a celdas
      hojaExcel.addImage(logo, 'D1:F5');
      //Combianr celdas
      hojaExcel.mergeCells('A7:K7');
  
      hojaExcel.getCell('C7').value=tituloReporte;
      hojaExcel.getCell('C7').alignment={ vertical: 'middle', horizontal: 'center' };
      const filaTitulo = hojaExcel.getRow(7);
      filaTitulo.font={ name: 'Calibri', family: 4, size: 17, bold: true};
      hojaExcel.addRow([]);
      const filaFechaReporte = hojaExcel.addRow(['Fecha Reporte : ' + moment().format('YYYY-MM-DD')]);
      const filaUsuario = hojaExcel.addRow(['Usuario : ' + this._settingsService.getInfoUser().nombre_user +' '+ this._settingsService.getInfoUser().apellido_user]);
      //Fecha desde fecha hasta
      //const fechaDesdeXls = hojaExcel.addRow(['Fecha Desde : ' + fechaDesde]);
      //const fechaHastaXls = hojaExcel.addRow(['Fecha Hasta : ' + fechaHasta]);
  
  
       // Blank Row
      hojaExcel.addRow([]);
      hojaExcel.mergeCells('A12:J12');
      hojaExcel.mergeCells('K12:R12');
      hojaExcel.mergeCells('S12:V12');
      hojaExcel.mergeCells('W12:AB12');
      hojaExcel.getCell('A12').value='DATOS GENERALES MANZANA';
      hojaExcel.getCell('A12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('A12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'A9F5D0'},
        bgColor:{argb:'A9F5D0'}
      };
      hojaExcel.getCell('A12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('K12').value='OCUPACIÓN DE SUELO';
      hojaExcel.getCell('K12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('K12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'0080FF'},
        bgColor:{argb:'0080FF'}
      };
      hojaExcel.getCell('K12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('V12').value='EDIFICABILIDAD';
      hojaExcel.getCell('V12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('V12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'AC58FA'},
        bgColor:{argb:'AC58FA'}
      };
      hojaExcel.getCell('V12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('W12').value='CAPACIDAD RECEPTIVA';
      hojaExcel.getCell('W12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('W12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'ACFA58'},
        bgColor:{argb:'ACFA58'}
      };
      hojaExcel.getCell('W12').alignment={ vertical: 'middle', horizontal: 'center' };
      // Add Header Row
      const headerRow = hojaExcel.addRow(cabeceraCeldas);
  
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
  
      //Fijo ancho de las columnas
      hojaExcel.getColumn(1).width = 25;
      hojaExcel.getColumn(2).width = 25;
      hojaExcel.getColumn(3).width = 25;
      hojaExcel.getColumn(4).width = 25;
      hojaExcel.getColumn(5).width = 15;
      hojaExcel.getColumn(6).width = 15;
      hojaExcel.getColumn(7).width = 15;
      hojaExcel.getColumn(8).width = 15;
      hojaExcel.getColumn(9).width = 15;
      hojaExcel.getColumn(10).width = 15;
      hojaExcel.getColumn(11).width = 15;
      hojaExcel.getColumn(12).width = 15;
      hojaExcel.getColumn(13).width = 15;
      hojaExcel.getColumn(14).width = 15;
      hojaExcel.getColumn(15).width = 15;
      hojaExcel.getColumn(16).width = 15;
      hojaExcel.getColumn(17).width = 15;
      hojaExcel.getColumn(18).width = 15;
      hojaExcel.getColumn(19).width = 15;
      hojaExcel.getColumn(20).width = 15;
      hojaExcel.getColumn(21).width = 15;
      hojaExcel.getColumn(22).width = 15;
      hojaExcel.getColumn(23).width = 15;
      hojaExcel.getColumn(24).width = 15;
      hojaExcel.getColumn(25).width = 15;
      hojaExcel.getColumn(26).width = 15;
      hojaExcel.getColumn(27).width = 15;
      hojaExcel.getColumn(28).width = 15;
      
  
      //variable auxiliar de recorrido
      let aux=14;
      //Recorro y Genero los datos
      listaManzanas.forEach(d => {
        hojaExcel.getCell(`A${aux}`).value=d.info_manzana.geografia.nombre_pais;
        hojaExcel.getCell(`B${aux}`).value=d.info_manzana.geografia.nombre_provincia;
        hojaExcel.getCell(`C${aux}`).value=d.info_manzana.geografia.nombre_ciudad;
        hojaExcel.getCell(`D${aux}`).value=d.info_manzana.geografia.nombre_parroquia;
        hojaExcel.getCell(`E${aux}`).value=d.info_manzana.geografia.cod_provincia;
        hojaExcel.getCell(`F${aux}`).value=d.info_manzana.geografia.cod_ciudad;
        hojaExcel.getCell(`G${aux}`).value=d.info_manzana.geografia.cod_parroquia;
        hojaExcel.getCell(`H${aux}`).value=d.info_manzana.cod_zona_man;
        hojaExcel.getCell(`I${aux}`).value=d.info_manzana.cod_sector_man;
        hojaExcel.getCell(`J${aux}`).value=d.info_manzana.cod_man;
        hojaExcel.getCell(`K${aux}`).value=d.info_manzana.cantidad_lotes_mos;
        hojaExcel.getCell(`L${aux}`).value=d.info_manzana.area_edificada_mos;
        hojaExcel.getCell(`M${aux}`).value=d.info_manzana.area_edificable_mos;
        hojaExcel.getCell(`N${aux}`).value=d.info_manzana.nivel_ocupacion_mos;
        hojaExcel.getCell(`O${aux}`).value=d.info_manzana.area_vacante_mos;
        hojaExcel.getCell(`P${aux}`).value=d.info_manzana.lotes_vacantes_mos;
        hojaExcel.getCell(`Q${aux}`).value=d.info_manzana.area_total_mos;
        hojaExcel.getCell(`R${aux}`).value=d.info_manzana.lotes_ocupados_mos;
        hojaExcel.getCell(`S${aux}`).value=d.info_manzana.area_edificada_ed;
        hojaExcel.getCell(`T${aux}`).value=d.info_manzana.area_edificable_ed;
        hojaExcel.getCell(`U${aux}`).value=d.info_manzana.nivel_edificabilidad_ed;
        hojaExcel.getCell(`V${aux}`).value=d.info_manzana.area_edificar_ed;
        hojaExcel.getCell(`W${aux}`).value=d.info_manzana.area_vivienda_total_cr;
        hojaExcel.getCell(`X${aux}`).value=d.info_manzana.poblacion_cr;
        hojaExcel.getCell(`Y${aux}`).value=d.info_manzana.indice_vivienda_cr;
        hojaExcel.getCell(`Z${aux}`).value=d.info_manzana.actual_cr;
        hojaExcel.getCell(`AA${aux}`).value=d.info_manzana.potencial_cr;
        hojaExcel.getCell(`AB${aux}`).value=d.info_manzana.desidad_cr;
        
        
        aux++;
      });
      
     
      // Generate Excel File with given name
      libroExcel.xlsx.writeBuffer().then((data: any) => {
       
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       
        fs.saveAs(blob, `ReporteTodasManzanas-${moment().format('YYYY-MM-DD')}.xlsx`);
      });
      
  
  
    }

    reporteExcelManzanasXUbigeo(listaManzanas:any, datosGad:any, datosGeografia:any){
      const tituloReporte:any=datosGad.nombre;
      
     
      const cabeceraCeldas = [
        
        'Cod. Zona',
        'Cod. Sector',
        'Cod. Manzana',
        'Cantidad Lotes',
        'Área Edificada (m2)',
        'Área Edificable (m2)',
        'Nivel Ocupación (%)',
        'Área Vacante (m2)',
        'Lotes Vacantes',
        'Área Total (m2)',
        'Lotes Ocupados',
        'Área Edificada ed. (m2)',
        'Área Edificable ed. (m2)',
        'Nivel Edificabilidad ed. (%)',
        'Área Edificar ed (m2)',
        'Área Vivienda (m2)',
        'Población (hab)',
        'Índice Poblacional  (m2/hab)',
        'Actual CR (hab)',
        'Potencial CR (hab)',
        'Densidad CR (hab/Ha)'

      ];
      //const libroExcel = new Excel.Workbook();
      const libroExcel = new Workbook();
      const hojaExcel = libroExcel.addWorksheet('Manzanas');
  
      // Add Image
      const logo = libroExcel.addImage({
        base64: logoFile.logoBase64,
        extension: 'jpeg',
      });
      //Agregar imagen a celdas
      hojaExcel.addImage(logo, 'D1:F5');
      //Combianr celdas
      hojaExcel.mergeCells('A7:K7');
  
      hojaExcel.getCell('C7').value=tituloReporte;
      hojaExcel.getCell('C7').alignment={ vertical: 'middle', horizontal: 'center' };
      const filaTitulo = hojaExcel.getRow(7);
      filaTitulo.font={ name: 'Calibri', family: 4, size: 17, bold: true};
      hojaExcel.addRow([]);
      const filaFechaReporte = hojaExcel.addRow(['Fecha Reporte : ' + moment().format('YYYY-MM-DD')]);
      const filaUsuario = hojaExcel.addRow(['Usuario : ' + this._settingsService.getInfoUser().nombre_user +' '+ this._settingsService.getInfoUser().apellido_user]);
      hojaExcel.getCell('D9').value=`País: ${datosGeografia.nombre_pais}`;
      hojaExcel.getCell('D10').value=`Provincia: ${datosGeografia.nombre_provincia} (${datosGeografia.cod_provincia})`;
      hojaExcel.getCell('G9').value=`Cantón: ${datosGeografia.nombre_ciudad} (${datosGeografia.cod_ciudad})`;
      hojaExcel.getCell('G10').value=`Parroquia: ${datosGeografia.nombre_parroquia} (${datosGeografia.cod_parroquia})`;
      
  
       // Blank Row
      hojaExcel.addRow([]);
      hojaExcel.mergeCells('A12:D12');
      hojaExcel.mergeCells('E12:K12');
      hojaExcel.mergeCells('L12:O12');
      hojaExcel.mergeCells('P12:U12');
      hojaExcel.getCell('A12').value='DATOS GENERALES MANZANA';
      hojaExcel.getCell('A12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('A12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'A9F5D0'},
        bgColor:{argb:'A9F5D0'}
      };
      hojaExcel.getCell('A12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('E12').value='OCUPACIÓN DE SUELO';
      hojaExcel.getCell('E12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('E12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'0080FF'},
        bgColor:{argb:'0080FF'}
      };
      hojaExcel.getCell('E12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('L12').value='EDIFICABILIDAD';
      hojaExcel.getCell('L12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('L12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'AC58FA'},
        bgColor:{argb:'AC58FA'}
      };
      hojaExcel.getCell('L12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('P12').value='CAPACIDAD RECEPTIVA';
      hojaExcel.getCell('P12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('P12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'ACFA58'},
        bgColor:{argb:'ACFA58'}
      };
      hojaExcel.getCell('P12').alignment={ vertical: 'middle', horizontal: 'center' };
      // Add Header Row
      const headerRow = hojaExcel.addRow(cabeceraCeldas);
  
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
  
      //Fijo ancho de las columnas
      hojaExcel.getColumn(1).width = 15;
      hojaExcel.getColumn(2).width = 15;
      hojaExcel.getColumn(3).width = 15;
      hojaExcel.getColumn(4).width = 15;
      hojaExcel.getColumn(5).width = 15;
      hojaExcel.getColumn(6).width = 15;
      hojaExcel.getColumn(7).width = 15;
      hojaExcel.getColumn(8).width = 15;
      hojaExcel.getColumn(9).width = 15;
      hojaExcel.getColumn(10).width = 15;
      hojaExcel.getColumn(11).width = 15;
      hojaExcel.getColumn(12).width = 15;
      hojaExcel.getColumn(13).width = 15;
      hojaExcel.getColumn(14).width = 15;
      hojaExcel.getColumn(15).width = 15;
      hojaExcel.getColumn(16).width = 15;
      hojaExcel.getColumn(17).width = 15;
      hojaExcel.getColumn(18).width = 15;
      hojaExcel.getColumn(19).width = 15;
      hojaExcel.getColumn(20).width = 15;

      
  
      //variable auxiliar de recorrido
      let aux=14;
      //Recorro y Genero los datos
      listaManzanas.forEach(d => {
        hojaExcel.getCell(`A${aux}`).value=d.info_manzana.cod_zona_man;
        hojaExcel.getCell(`B${aux}`).value=d.info_manzana.cod_sector_man;
        hojaExcel.getCell(`C${aux}`).value=d.info_manzana.cod_man;
        hojaExcel.getCell(`D${aux}`).value=d.info_manzana.cantidad_lotes_mos;
        hojaExcel.getCell(`E${aux}`).value=d.info_manzana.area_edificada_mos;
        hojaExcel.getCell(`F${aux}`).value=d.info_manzana.area_edificable_mos;
        hojaExcel.getCell(`G${aux}`).value=d.info_manzana.nivel_ocupacion_mos;
        hojaExcel.getCell(`H${aux}`).value=d.info_manzana.area_vacante_mos;
        hojaExcel.getCell(`I${aux}`).value=d.info_manzana.lotes_vacantes_mos;
        hojaExcel.getCell(`J${aux}`).value=d.info_manzana.area_total_mos;
        hojaExcel.getCell(`K${aux}`).value=d.info_manzana.lotes_ocupados_mos;
        hojaExcel.getCell(`L${aux}`).value=d.info_manzana.area_edificada_ed;
        hojaExcel.getCell(`M${aux}`).value=d.info_manzana.area_edificable_ed;
        hojaExcel.getCell(`N${aux}`).value=d.info_manzana.nivel_edificabilidad_ed;
        hojaExcel.getCell(`O${aux}`).value=d.info_manzana.area_edificar_ed;
        hojaExcel.getCell(`P${aux}`).value=d.info_manzana.area_vivienda_total_cr;
        hojaExcel.getCell(`Q${aux}`).value=d.info_manzana.poblacion_cr;
        hojaExcel.getCell(`R${aux}`).value=d.info_manzana.indice_vivienda_cr;
        hojaExcel.getCell(`S${aux}`).value=d.info_manzana.actual_cr;
        hojaExcel.getCell(`T${aux}`).value=d.info_manzana.potencial_cr;
        hojaExcel.getCell(`U${aux}`).value=d.info_manzana.desidad_cr;
        
        
        aux++;
      });
      
     
      // Generate Excel File with given name
      libroExcel.xlsx.writeBuffer().then((data: any) => {
       
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       
        fs.saveAs(blob, `ReporteTodasManzanas-${moment().format('YYYY-MM-DD')}.xlsx`);
      });
      
  
  
    }
  
    reporteExcelLotesTodos(listaLotes:any, datosGad:any){
     
      const tituloReporte:any=datosGad.nombre;
      
     
      const cabeceraCeldas = [
        'País',                              //A                                     
        'Provincia',                         //B          
        'Ciudad',                            //C       
        'Parroquia',                         //D          
        'Cod. Provincia',                    //E               
        'Cod. Ciudad',                       //F            
        'Cod. Parroquia',                    //G               
        'Manzana',                           //H        
        'Cod. Lote',                         //I          
        'Área Total (m2)',                   //J                
        'Vacio',                             //K      
        'Área Construcción (m2)',            //L                       
        'Área Vacante (m2)',                 //M                  
        'Num Pisos',                         //N          
        'Área Edificada X Pisos (m2)',       //P                            
        'Área Edificada Total (m2)',         //Q                          
        'COS PB (%)',                        //R           
        'Área Edificable PB. (m2)',          //S                         
        'Nivel Edificabilidad PB (%)',       //T                            
        'COS Total (%)',                     //U              
        'Sector',                            //V       
        'Área Edificable Total (m2)',        //W                           
        'Retiro Frontal (m)',               //X                    
        'Retiro Lateral (m)',               //Y                    
        'Retiro Posterior (m)'              //Z                     
          

      ];
      //const libroExcel = new Excel.Workbook();
      const libroExcel = new Workbook();
      const hojaExcel = libroExcel.addWorksheet('Lotes');

      // Add Image
      const logo = libroExcel.addImage({
        base64: logoFile.logoBase64,
        extension: 'jpeg',
      });
      //Agregar imagen a celdas
      hojaExcel.addImage(logo, 'D1:F5');
      //Combianr celdas
      hojaExcel.mergeCells('A7:K7');
  
      hojaExcel.getCell('C7').value=tituloReporte;
      hojaExcel.getCell('C7').alignment={ vertical: 'middle', horizontal: 'center' };
      const filaTitulo = hojaExcel.getRow(7);
      filaTitulo.font={ name: 'Calibri', family: 4, size: 17, bold: true};
      hojaExcel.addRow([]);
      const filaFechaReporte = hojaExcel.addRow(['Fecha Reporte : ' + moment().format('YYYY-MM-DD')]);
      const filaUsuario = hojaExcel.addRow(['Usuario : ' + this._settingsService.getInfoUser().nombre_user +' '+ this._settingsService.getInfoUser().apellido_user]);
    
  
       // Blank Row
      hojaExcel.addRow([]);hojaExcel.addRow([]);
      //Ajustar texto
      hojaExcel.getColumn('O').alignment = hojaExcel.getColumn('U').alignment= { vertical: 'middle', horizontal: 'center', wrapText: true };

      /* hojaExcel.mergeCells('A12:J12');
      hojaExcel.mergeCells('K12:R12');
      hojaExcel.mergeCells('S12:V12');
      hojaExcel.mergeCells('W12:AB12');
      hojaExcel.getCell('A12').value='DATOS GENERALES MANZANA';
      hojaExcel.getCell('A12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('A12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'A9F5D0'},
        bgColor:{argb:'A9F5D0'}
      };
      hojaExcel.getCell('A12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('K12').value='OCUPACIÓN DE SUELO';
      hojaExcel.getCell('K12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('K12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'0080FF'},
        bgColor:{argb:'0080FF'}
      };
      hojaExcel.getCell('K12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('V12').value='EDIFICABILIDAD';
      hojaExcel.getCell('V12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('V12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'AC58FA'},
        bgColor:{argb:'AC58FA'}
      };
      hojaExcel.getCell('V12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('W12').value='CAPACIDAD RECEPTIVA';
      hojaExcel.getCell('W12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('W12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'ACFA58'},
        bgColor:{argb:'ACFA58'}
      };
      hojaExcel.getCell('W12').alignment={ vertical: 'middle', horizontal: 'center' }; */
      // Add Header Row
      const headerRow = hojaExcel.addRow(cabeceraCeldas);
  
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
  
      //Fijo ancho de las columnas
      hojaExcel.getColumn(1).width = 25;
      hojaExcel.getColumn(2).width = 25;
      hojaExcel.getColumn(3).width = 25;
      hojaExcel.getColumn(4).width = 25;
      hojaExcel.getColumn(5).width = 15;
      hojaExcel.getColumn(6).width = 15;
      hojaExcel.getColumn(7).width = 15;
      hojaExcel.getColumn(8).width = 15;
      hojaExcel.getColumn(9).width = 15;
      hojaExcel.getColumn(10).width = 15;
      hojaExcel.getColumn(11).width = 15;
      hojaExcel.getColumn(12).width = 15;
      hojaExcel.getColumn(13).width = 15;
      hojaExcel.getColumn(14).width = 15;
      hojaExcel.getColumn(15).width = 15;
      hojaExcel.getColumn(16).width = 15;
      hojaExcel.getColumn(17).width = 15;
      hojaExcel.getColumn(18).width = 15;
      hojaExcel.getColumn(19).width = 15;
      hojaExcel.getColumn(20).width = 15;
      hojaExcel.getColumn(21).width = 15;
      hojaExcel.getColumn(22).width = 15;
      hojaExcel.getColumn(23).width = 15;
      hojaExcel.getColumn(24).width = 15;
      hojaExcel.getColumn(25).width = 15;
      
  
      //variable auxiliar de recorrido
      let aux=14;
      //Recorro y Genero los datos
      listaLotes.forEach(d => {
        hojaExcel.getCell(`A${aux}`).value=d.info_lote.manzana.geografia.nombre_pais;
        hojaExcel.getCell(`B${aux}`).value=d.info_lote.manzana.geografia.nombre_provincia;
        hojaExcel.getCell(`C${aux}`).value=d.info_lote.manzana.geografia.nombre_ciudad;
        hojaExcel.getCell(`D${aux}`).value=d.info_lote.manzana.geografia.nombre_parroquia;
        hojaExcel.getCell(`E${aux}`).value=d.info_lote.manzana.geografia.cod_provincia;
        hojaExcel.getCell(`F${aux}`).value=d.info_lote.manzana.geografia.cod_ciudad;
        hojaExcel.getCell(`G${aux}`).value=d.info_lote.manzana.geografia.cod_parroquia;
        hojaExcel.getCell(`H${aux}`).value=`${d.info_lote.manzana.cod_zona_man}-${d.info_lote.manzana.cod_sector_man}-${d.info_lote.manzana.cod_man}`;
        hojaExcel.getCell(`I${aux}`).value=d.info_lote.cod_lote;
        hojaExcel.getCell(`J${aux}`).value=d.info_lote.m2_total_lote;
        hojaExcel.getCell(`K${aux}`).value=d.info_lote.vacio_lote;
        hojaExcel.getCell(`L${aux}`).value=d.info_lote.m2_contruccion_lote;
        hojaExcel.getCell(`M${aux}`).value=d.info_lote.m2_vacante_lote;
        hojaExcel.getCell(`N${aux}`).value=d.info_lote.num_pisos_lote;
        let valorPisos:any='';
        let tam=d.info_lote.info_pisos;
        if(tam != null) {tam=Object.keys(d.info_lote.info_pisos).length;}
        else { tam=0; valorPisos='SIN PISOS'};
        
        for(let i=0; i<tam;i++){
          valorPisos=valorPisos + `#${d.info_lote.info_pisos[i].num_piso} (${d.info_lote.info_pisos[i].area_piso} m2) \
          `;
        }
       
        hojaExcel.getCell(`O${aux}`).value=valorPisos;
        hojaExcel.getCell(`P${aux}`).value=d.info_lote.area_edificada_total;
        hojaExcel.getCell(`Q${aux}`).value=d.info_lote.cos_pb;
        hojaExcel.getCell(`R${aux}`).value=d.info_lote.area_edificable_pb;
        hojaExcel.getCell(`S${aux}`).value=d.info_lote.nivel_ocupacion;
        hojaExcel.getCell(`T${aux}`).value=d.info_lote.cos_total;
        hojaExcel.getCell(`U${aux}`).value=d.info_lote.sector;
        hojaExcel.getCell(`V${aux}`).value=d.info_lote.area_edificable_total;
        hojaExcel.getCell(`W${aux}`).value=d.info_lote.ret_frontal_lote;
        hojaExcel.getCell(`X${aux}`).value=d.info_lote.ret_lateral_lote;
        hojaExcel.getCell(`Y${aux}`).value=d.info_lote.ret_posterior_lote;
        
        
        
        aux++;
      });
      
     
      // Generate Excel File with given name
      libroExcel.xlsx.writeBuffer().then((data: any) => {
       
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       
        fs.saveAs(blob, `ReporteTodasLotes-${moment().format('YYYY-MM-DD')}.xlsx`);
      });
      
  
  
    }

    reporteExcelLotesManzana(listaLotes:any, datosGad:any, datosManzana:any){
      const tituloReporte:any=datosGad.nombre;
      
     
      const cabeceraCeldas = [
                    
        'Manzana',                           //H        
        'Cod. Lote',                         //I          
        'Área Total (m2)',                   //J                
        'Vacio',                             //K      
        'Área Construcción (m2)',            //L                       
        'Área Vacante (m2)',                 //M                  
        'Num Pisos',                         //N          
        'Área Edificada X Pisos (m2)',       //P                            
        'Área Edificada Total (m2)',         //Q                          
        'COS PB (%)',                        //R           
        'Área Edificable PB. (m2)',          //S                         
        'Nivel Edificabilidad PB (%)',       //T                            
        'COS Total (%)',                     //U              
        'Sector',                            //V       
        'Área Edificable Total (m2)',        //W                           
        'Retiro Frontal (m)',               //X                    
        'Retiro Lateral (m)',               //Y                    
        'Retiro Posterior (m)'              //Z                     
          

      ];
      const libroExcel = new Workbook();
      //const libroExcel = new Excel.Workbook();
      const hojaExcel = libroExcel.addWorksheet('Lotes');

      // Add Image
      const logo = libroExcel.addImage({
        base64: logoFile.logoBase64,
        extension: 'jpeg',
      });
      //Agregar imagen a celdas
      hojaExcel.addImage(logo, 'D1:F5');
      //Combianr celdas
      hojaExcel.mergeCells('A7:K7');
  
      hojaExcel.getCell('C7').value=tituloReporte;
      hojaExcel.getCell('C7').alignment={ vertical: 'middle', horizontal: 'center' };
      const filaTitulo = hojaExcel.getRow(7);
      filaTitulo.font={ name: 'Calibri', family: 4, size: 17, bold: true};
      hojaExcel.addRow([]);
      const filaFechaReporte = hojaExcel.addRow(['Fecha Reporte : ' + moment().format('YYYY-MM-DD')]);
      const filaUsuario = hojaExcel.addRow(['Usuario : ' + this._settingsService.getInfoUser().nombre_user +' '+ this._settingsService.getInfoUser().apellido_user]);
      const filaCodManzana = hojaExcel.addRow(['Manzana : ' + `${datosManzana.geografia.codigos}-${datosManzana.cod_zona_man}-${datosManzana.cod_sector_man}-${datosManzana.cod_man}`]);
      hojaExcel.getCell('D9').value=`País: ${datosManzana.geografia.nombre_pais}`;
      hojaExcel.getCell('D10').value=`Provincia: ${datosManzana.geografia.nombre_provincia} (${datosManzana.geografia.cod_provincia})`;
      hojaExcel.getCell('G9').value=`Cantón: ${datosManzana.geografia.nombre_ciudad} (${datosManzana.geografia.cod_ciudad})`;
      hojaExcel.getCell('G10').value=`Parroquia: ${datosManzana.geografia.nombre_parroquia} (${datosManzana.geografia.cod_parroquia})`;
       // Blank Row
      hojaExcel.addRow([]);hojaExcel.addRow([]);
      //Ajustar texto
      hojaExcel.getColumn('H').alignment = hojaExcel.getColumn('N').alignment= { vertical: 'middle', horizontal: 'center', wrapText: true };
      //Titulo Lista de Manzanas
      hojaExcel.mergeCells('D12:G12');
      hojaExcel.getCell('D12').value='Lista de Lotes';
      hojaExcel.getCell('D12').alignment={ vertical: 'middle', horizontal: 'center' };
      const filaListaLotes = hojaExcel.getRow(12);
      filaListaLotes.font={ name: 'Calibri', family: 4, size: 14, bold: true};
      /* hojaExcel.mergeCells('A12:J12');
      hojaExcel.mergeCells('K12:R12');
      hojaExcel.mergeCells('S12:V12');
      hojaExcel.mergeCells('W12:AB12');
      hojaExcel.getCell('A12').value='DATOS GENERALES MANZANA';
      hojaExcel.getCell('A12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('A12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'A9F5D0'},
        bgColor:{argb:'A9F5D0'}
      };
      hojaExcel.getCell('A12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('K12').value='OCUPACIÓN DE SUELO';
      hojaExcel.getCell('K12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('K12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'0080FF'},
        bgColor:{argb:'0080FF'}
      };
      hojaExcel.getCell('K12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('V12').value='EDIFICABILIDAD';
      hojaExcel.getCell('V12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('V12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'AC58FA'},
        bgColor:{argb:'AC58FA'}
      };
      hojaExcel.getCell('V12').alignment={ vertical: 'middle', horizontal: 'center' };
      hojaExcel.getCell('W12').value='CAPACIDAD RECEPTIVA';
      hojaExcel.getCell('W12').font={ name: 'Calibri', family: 4, size: 13, bold: true};
      hojaExcel.getCell('W12').fill= {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:'ACFA58'},
        bgColor:{argb:'ACFA58'}
      };
      hojaExcel.getCell('W12').alignment={ vertical: 'middle', horizontal: 'center' }; */
      // Add Header Row
      const headerRow = hojaExcel.addRow(cabeceraCeldas);
  
      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
  
      //Fijo ancho de las columnas
      hojaExcel.getColumn(1).width = 35;
      hojaExcel.getColumn(2).width = 15;
      hojaExcel.getColumn(3).width = 15;
      hojaExcel.getColumn(4).width = 15;
      hojaExcel.getColumn(5).width = 15;
      hojaExcel.getColumn(6).width = 15;
      hojaExcel.getColumn(7).width = 15;
      hojaExcel.getColumn(8).width = 15;
      hojaExcel.getColumn(9).width = 15;
      hojaExcel.getColumn(10).width = 15;
      hojaExcel.getColumn(11).width = 15;
      hojaExcel.getColumn(12).width = 15;
      hojaExcel.getColumn(13).width = 15;
      hojaExcel.getColumn(14).width = 15;
      hojaExcel.getColumn(15).width = 15;
      hojaExcel.getColumn(16).width = 15;
      hojaExcel.getColumn(17).width = 15;
      hojaExcel.getColumn(18).width = 15;
     
      
  
      //variable auxiliar de recorrido
      let aux=15;
      //Recorro y Genero los datos
      listaLotes.forEach(d => {
      
        hojaExcel.getCell(`A${aux}`).value=`${datosManzana.geografia.codigos}-${datosManzana.cod_zona_man}-${datosManzana.cod_sector_man}-${datosManzana.cod_man}`;
        hojaExcel.getCell(`B${aux}`).value=d.info_lote.cod_lote;
        hojaExcel.getCell(`C${aux}`).value=d.info_lote.m2_total_lote;
        hojaExcel.getCell(`D${aux}`).value=d.info_lote.vacio_lote;
        hojaExcel.getCell(`E${aux}`).value=d.info_lote.m2_contruccion_lote;
        hojaExcel.getCell(`F${aux}`).value=d.info_lote.m2_vacante_lote;
        hojaExcel.getCell(`G${aux}`).value=d.info_lote.num_pisos_lote;
        let valorPisos:any='';
        let tam=d.info_lote.info_pisos;
        if(tam != null) {tam=Object.keys(d.info_lote.info_pisos).length;}
        else { tam=0; valorPisos='SIN PISOS'};
        
        for(let i=0; i<tam;i++){
          valorPisos=valorPisos + `#${d.info_lote.info_pisos[i].num_piso} (${d.info_lote.info_pisos[i].area_piso} m2) \
          `;
        }
       
        hojaExcel.getCell(`H${aux}`).value=valorPisos;
        hojaExcel.getCell(`I${aux}`).value=d.info_lote.area_edificada_total;
        hojaExcel.getCell(`J${aux}`).value=d.info_lote.cos_pb;
        hojaExcel.getCell(`K${aux}`).value=d.info_lote.area_edificable_pb;
        hojaExcel.getCell(`L${aux}`).value=d.info_lote.nivel_ocupacion;
        hojaExcel.getCell(`M${aux}`).value=d.info_lote.cos_total;
        hojaExcel.getCell(`N${aux}`).value=d.info_lote.sector;
        hojaExcel.getCell(`O${aux}`).value=d.info_lote.area_edificable_total;
        hojaExcel.getCell(`P${aux}`).value=d.info_lote.ret_frontal_lote;
        hojaExcel.getCell(`Q${aux}`).value=d.info_lote.ret_lateral_lote;
        hojaExcel.getCell(`R${aux}`).value=d.info_lote.ret_posterior_lote;
        
        
        
        aux++;
      });
      
     
      // Generate Excel File with given name
      libroExcel.xlsx.writeBuffer().then((data: any) => {
       
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       
        fs.saveAs(blob, `ReporteTodasLotes-${moment().format('YYYY-MM-DD')}.xlsx`);
      });
      
  
  
    }
            
}
