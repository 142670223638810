import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading3',
  templateUrl: './loading3.component.html',
  styleUrls: ['./loading3.component.css']
})
export class Loading3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}
