import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { ManzanasService } from '../../services/gplan_calculos/manzanas.service';
import { GeografiaService } from '../../services/geografia.service';
import { SectorizacionService } from '../../services/gplan_calculos/sectorizacion.service';
import { ManzanaModel } from '../../models/calculos.models';
import { UploadService } from '../../services/gplan_metas/upload.service';
import { ReturnStatement } from '@angular/compiler';
import { exit } from 'process';
import { flatMap } from 'rxjs/operators';
import { ReportesExcelService } from '../../services/gplan_calculos/reportes-excel.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-manzanas',
  templateUrl: './manzanas.component.html',
  styles: []
})
export class ManzanasComponent implements OnInit {
   //necesario colocar estas variables para la paginacion
   p: number = 1;
   filter:any;
    cargando_tabla:boolean=true;
    documento:File;

    //MENSAJES TOAST
    toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });
  
 
   pais:any=null;
   provincia:any=null;
   ciudad:any=null;
   parroquia:any=null;
   manzana:any=null;
   tipo:string='N';
 
   manzanaTarget:ManzanaModel={
    pk_man:0,
    fk_ubigeo:null,
    cod_zona_man:null,
    cod_sector_man:null,
    cod_man:null,
    observacion_man:null,
    adjunto_man:null,
    poblacion_man:null    
   };
   parroquiaTarget:any={};
   infoTecnica:any={};

   listaNaciones:any[]=[];
   listaProvincias:any[]=[];
   listaCiudades:any[]=[];
   listaParroquias:any[]=[];
   listaManzanas:any[]=[];
   listaSectores:any[]=[];
   listaReportesManzanas:any[]=[];
   accion='ingresar';
   
   public formularioManzanas:FormGroup;
   public valoresInicialesForm:any;
 
   constructor(
     public _manzanaService:ManzanasService,
     public _geografiaService:GeografiaService,
     public _sectoresService:SectorizacionService,
     public router:Router,
     public activatedRoute:ActivatedRoute,
     private modalService: NgbModal,
     private configuracionModal: NgbModalConfig,
     private formBuilder: FormBuilder,
     private _uploadService: UploadService,
     private _reporteService:ReportesExcelService
     ) {
       this.configuracionModal.backdrop = 'static';
       this.configuracionModal.keyboard = false;
       
       }
 
     ngOnInit() {
       this.crearFormReactive();
       this.valoresInicialesForm=this.formularioManzanas.value;
       this.inicializar();
     }
   
     inicializar(){
       this.cargarPaises();
       
     }
 
     cargarSectores(){
       this._sectoresService.cargarDatos(this.ciudad)
           .subscribe((sectores:any)=>{
             this.listaSectores=Object.values(sectores);
             
           })
     }
 
     cargarPaises(){
       this._geografiaService.cargarGeografia(0)
           .subscribe((paises:any)=>{
             this.listaNaciones=Object.values(paises);
             this.cargando_tabla=false;
           })
     }
 
     cargarProvincias(){
     
       if(this.pais){
         this._geografiaService.cargarGeografia(this.pais)
             .subscribe((provincias:any)=>{
               this.listaProvincias=[];
               this.listaCiudades=[];
               this.listaParroquias=[];
               this.listaManzanas=[];
               this.provincia=this.ciudad=this.parroquia=this.manzana=null;
               this.parroquiaTarget={};
               this.listaProvincias=Object.values(provincias);
               this.cargando_tabla=false;
             })
         }
         else{
           swal.fire(
             'Faltan Datos',
             'Debe seleccionar pais o el pais seleccioando no contiene datos.',
             'warning'
           )
         }
     }
   
     cargarCiudades(){
       
       if(this.provincia){
       this._geografiaService.cargarGeografia(this.provincia)
           .subscribe((ciudades:any)=>{
             this.listaCiudades=[];
             this.listaParroquias=[];
             this.listaManzanas=[];
             this.ciudad=this.parroquia=this.manzana=null;
             this.parroquiaTarget={};
             this.listaCiudades=Object.values(ciudades);
             this.cargando_tabla=false;
           })
         }
         else{
           swal.fire(
             'Faltan Datos',
             'Debe seleccionar provincia o el provincia seleccioando no contiene datos.',
             'warning'
           )
         }
     }
   
     cargarParroquias(){
       if(this.ciudad){
       this._geografiaService.cargarGeografia(this.ciudad)
           .subscribe((parroquias:any)=>{
             this.listaParroquias=[];
             this.listaManzanas=[];
             this.parroquia=this.manzana=null;
             this.parroquiaTarget={};
             this.listaParroquias=Object.values(parroquias);
             this.cargando_tabla=false;
           })
         }
         else{
           this.cargando_tabla=true;
           this.cargando_tabla=false;
           swal.fire(
             'Faltan Datos',
             'Debe seleccionar ciudad o el ciudad seleccioando no contiene datos.',
             'warning'
           )
         }
     }
 
     cargarManzanas(parroquia:any){
       
       this.listaManzanas=[];
       this.parroquiaTarget=parroquia;
       if(this.parroquia){
        this.cargando_tabla=true;
         this._manzanaService.cargarDatos(this.parroquia)
         .subscribe((datos:any)=>{    
           this.listaManzanas=Object.values(datos);
           this.cargando_tabla=false;
         })
       }else{
         this.listaManzanas=[];
         this.cargando_tabla=false;
       }
     }
 

     upload(file:File){
      this.documento=null;
      this.documento=file[0];
      this.toast.fire({
        type: 'warning',
        title: 'Usted acaba de seleccionar el archivo a cargar, previo a esta acción debio asegurarse que sea el correcto.',
        timer: 6000
        
      });
    }

    validarCodigoManzanas(){
      //pasar datos al objeto target desde los controles del form
       //importante tiene que ir luego de la validacion de actualizar o insertar
       this.passDataFormBuiderToTarget();
      return this._manzanaService.validacionCodigoManzanas(this.manzanaTarget.cod_man,this.manzanaTarget.cod_zona_man,this.manzanaTarget.cod_sector_man,this.manzanaTarget.fk_ubigeo)
      .subscribe((manzanas:any)=>{
        
        let num=manzanas.length;
        if(num >= 2) {
          swal.fire(
            'Manzanas Duplicadas',
            'La combinación de Ubicación Geográfica, Zona, Sector y Código de Manzana ya existe, por favor revise los datos antes de realizar esta acción',
            'warning'
          ) 
        } else if(num === 1){
          let codigo= manzanas[0].pk_man;
          //console.log(`El numero de manzanzas es: ${manzanas[0].pk_man}`);
          if( codigo === this.manzanaTarget.pk_man ){
            this.confirmarGuardar();
          }else{
            swal.fire(
              'Manzanas Duplicadas',
              'La combinación de Ubicación Geográfica, Zona, Sector y Código de Manzana ya existe, por favor revise los datos antes de realizar esta acción',
              'warning'
            ) 
          }
            
        }        
        else{
          this.confirmarGuardar();
        }
      });
  
     }
  
    confirmarGuardar(){
       
      swal.fire({
        title: 'Confirmación',
        text: "Desea registrar información o documento adjunto de la manzana ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Aceptar'
      }).then((result) => {
        if (result.value) {
          if(this.documento ){
            this._uploadService.subirArchivo(this.manzanaTarget.pk_man,this.documento)
                .then((resp:any)=>{
                    //console.log(JSON.stringify(resp));
                    if(resp.hasOwnProperty('ok')){
                      if(resp.ok === true){
                        this.manzanaTarget.adjunto_man=resp.nombre_archivo
                        this.guardar();
                        swal.fire({
                          type: 'success',
                          title: 'Registro Guardado Exitosamente!!',
                          showConfirmButton: true,
                          timer: 3000
                        })
                      }
                    }
  
                })
                .catch( (err:any) => {
                  //console.log('Error en la carga...');
                  /* this.cargando=false; */
                })      
          }else{
  
            this.guardar();
          }
          
        }
      });
    }


 
     guardar(){
       let accionLabel;
      
            
       if(this.accion === 'ingresar'){ 
         accionLabel='I';
         //this.sector.audit_creacion=this._settingsService.getInfoUser();
       }else{ 
         //this.objetivoEstrategicoTarget.audit_modificacion=this._settingsService.getInfoUser();
         accionLabel='U';
       }
 
       //console.log(JSON.stringify(this.manzanaTarget));
       this._manzanaService.crud(accionLabel,this.manzanaTarget)
         .subscribe((resp:any)=>{
           this.cargarManzanas(this.parroquiaTarget);
           this.accion='';
           this.close('');
          
         });  
     }
 
     eliminar(row:any){
       swal.fire({
         title: 'Confirmación',
         text: "Desea eliminar este registro?",
         type: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         cancelButtonText:'Cancelar',
         confirmButtonText: 'Eliminar'
     }).then((result) => {
       if (result.value) {
         this._manzanaService.crud('D',row)
             .subscribe((resp:any) => {
               this.cargarManzanas(this.parroquia);
               swal.fire(`Registro Eliminado!!`)
         });        
       }
     });
     
   }
 
       open(content,datos) {
        
         this.reset(datos);
         this.modalService.open(content, { size: 'lg' });           
     }

     open2(content,manzana) {
      this.infoTecnica=manzana;
      this.modalService.open(content, { size: 'lg' });           
    }
     
      close(content) {
         this.modalService.dismissAll(content);
       }
 
       reporteXls(parametro){
         if(parametro === 1){
          //1 todos
          this._reporteService.cargarReporteManzanas(0,'t').subscribe((datos:any) => {
            this._reporteService.reporteExcelManzanasTodas(datos.manzana,datos.gad);
          });
         }else{
          //2 por ubicacion geografica
          if(this.listaManzanas.length <= 0){
            swal.fire({
              //position: 'top',
              type: 'error',
              title: `No existen registros del cual sacar reportes.`,
              showConfirmButton: false,
              timer: 1500
            })
          }else{
            this._reporteService.cargarReporteManzanas(this.parroquia,'m').subscribe((datos:any) => {
              this._reporteService.reporteExcelManzanasXUbigeo(datos.manzana,datos.gad,datos.geografia);
            });  
          }
         }

       }
       //---------------------------------------------------------//
 //---------- METODOS DE REACTIVE FORMS --------------------//
 //---------------------------------------------------------//
   // convenience getter for easy access to form fields
   get controles() { return this.formularioManzanas.controls; }
 
   reset(manzana:any){
     this.formularioManzanas.reset(this.valoresInicialesForm);
     if(manzana === '{}'){
       this.accion='ingresar';
     }else{
     
       this.accion='actualizar';
       this.cargarFormReactive(manzana); 
     }
     
   }
   
   crearFormReactive(){
    
     this.formularioManzanas = this.formBuilder.group({
       pk_man:[0, { validators: [Validators.required], }],
       fk_ubigeo:[0, ],
       cod_zona_man:[null, { validators: [Validators.required], }],
       cod_sector_man:[null, { validators: [Validators.required], }],
       cod_man:[null, { validators: [Validators.required], }],
       observacion_man:[null, ],
       adjunto_man:[null,],
       poblacion_man:[0, { validators: [Validators.required], }],
     });
   }
 
 
   
   cargarFormReactive(manzana:ManzanaModel){
     this.formularioManzanas.controls['pk_man'].setValue(manzana.pk_man);
     this.formularioManzanas.controls['fk_ubigeo'].setValue(manzana.fk_ubigeo);
     this.formularioManzanas.controls['cod_zona_man'].setValue(manzana.cod_zona_man);
     this.formularioManzanas.controls['cod_sector_man'].setValue(manzana.cod_sector_man);
     this.formularioManzanas.controls['cod_man'].setValue(manzana.cod_man);
     this.formularioManzanas.controls['observacion_man'].setValue(manzana.observacion_man);
     this.formularioManzanas.controls['adjunto_man'].setValue(manzana.adjunto_man);
     this.formularioManzanas.controls['poblacion_man'].setValue(manzana.poblacion_man);
   }
 
   passDataFormBuiderToTarget(){
     if(this.accion === 'ingresar'){
       this.manzanaTarget.pk_man=0;
       this.manzanaTarget.fk_ubigeo=this.parroquiaTarget.pk_ubigeo;
     } 
     else {
       this.manzanaTarget.pk_man=this.formularioManzanas.get('pk_man').value;
       this.manzanaTarget.fk_ubigeo=this.parroquiaTarget.pk_ubigeo;
     }
     this.manzanaTarget.cod_zona_man=this.formularioManzanas.get('cod_zona_man').value;
     this.manzanaTarget.cod_sector_man=this.formularioManzanas.get('cod_sector_man').value;
     this.manzanaTarget.cod_man=this.formularioManzanas.get('cod_man').value;
     this.manzanaTarget.observacion_man=this.formularioManzanas.get('observacion_man').value;
     this.manzanaTarget.adjunto_man=this.formularioManzanas.get('adjunto_man').value;
     this.manzanaTarget.poblacion_man=this.formularioManzanas.get('poblacion_man').value;
   }

  
 
 }
 