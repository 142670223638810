import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-info',
  templateUrl: './tooltip-info.component.html',
  styleUrls: ['./tooltip-info.component.css']
})
export class TooltipInfoComponent implements OnInit {
  @Input('titulo') titulo:string='Sin Titulo';
  @Input('info') info:string='Sin Información';
  constructor() { }

  ngOnInit() {
  }

}
