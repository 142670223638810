import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { SectoresModel } from '../../models/calculos.models';
import { SectorizacionService } from '../../services/gplan_calculos/sectorizacion.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GeografiaService } from '../../services/geografia.service';
@Component({
  selector: 'app-sectores',
  templateUrl: './sectores.component.html',
  styles: []
})
export class SectoresComponent implements OnInit {
  //necesario colocar estas variables para la paginacion
  p: number = 1;
  filter:any;
 cargando_tabla:boolean=true;

 pais:any=null;
 provincia:any=null;
 ciudad:any=null;

 sectorTarget:SectoresModel={
    pk_sectorpla:0,
    nombre_sectorpla:null,
    fk_ubigeo:null,
    cospb_sectorpla:null,
    costotal_sectorpla:null,
    ret_frontal_sectorpla:null,
    ret_posterior_sectorpla:null,
    ret_der_sectorpla:null,
    ret_izq_sectorpla:null
}
listaSectores:any[]=[];
listaNaciones:any[]=[];
listaProvincias:any[]=[];
listaCiudades:any[]=[];
accion='ingresar';

public formularioSectores:FormGroup;

constructor(public _sectorService:SectorizacionService,
  public _geografiaService:GeografiaService,
  public router:Router,
  public activatedRoute:ActivatedRoute,
  private modalService: NgbModal,
  private configuracionModal: NgbModalConfig,
  private formBuilder: FormBuilder,
  ) {
    this.configuracionModal.backdrop = 'static';
    this.configuracionModal.keyboard = false;
    this.crearFormReactive();
    }

    ngOnInit() {
      this.inicializar();
    }
  
    inicializar(){
      this.cargarPaises();
    }
  
    cargarPaises(){
      this._geografiaService.cargarGeografia(0)
          .subscribe((paises:any)=>{
            this.listaNaciones=Object.values(paises);
            this.cargando_tabla=false;
          })
    }

    cargarProvincias(){
    
      if(this.pais){
        this._geografiaService.cargarGeografia(this.pais)
            .subscribe((provincias:any)=>{
              this.listaProvincias=[];
              this.listaCiudades=[];
              this.listaSectores=[];              
              this.provincia=this.ciudad=null;
              this.listaProvincias=Object.values(provincias);
              this.cargando_tabla=false;
            })
        }
        else{
          swal.fire(
            'Faltan Datos',
            'Debe seleccionar pais o el pais seleccioando no contiene datos.',
            'warning'
          )
        }
    }
  
    cargarCiudades(){
      
      if(this.provincia){
      this._geografiaService.cargarGeografia(this.provincia)
          .subscribe((ciudades:any)=>{
            this.listaCiudades=[];
            this.listaSectores=[];
            this.ciudad=null;
            this.listaCiudades=Object.values(ciudades);
            this.cargando_tabla=false;
          })
        }
        else{
          swal.fire(
            'Faltan Datos',
            'Debe seleccionar provincia o el provincia seleccioando no contiene datos.',
            'warning'
          )
        }
    }
   
    cargarSectores(){
      this.listaSectores=[];
      if(this.ciudad){
        this.cargando_tabla=true;
        this._sectorService.cargarDatos(this.ciudad)
        .subscribe((datos:any)=>{      
          this.listaSectores=Object.values(datos);
          this.cargando_tabla=false;
        })

      }else{
        this.listaSectores=[];
        
      }
    }


    guardar(){
      let accionLabel;
      //pasar datos al objeto target desde los controles del form
      //importante tiene que ir luego de la validacion de actualizar o insertar
      this.passDataFormBuiderToTarget();
           
      if(this.accion === 'ingresar'){ 
        accionLabel='I';
        //this.sector.audit_creacion=this._settingsService.getInfoUser();
      }else{ 
        //this.objetivoEstrategicoTarget.audit_modificacion=this._settingsService.getInfoUser();
        accionLabel='U';
      }

   
      this._sectorService.crud(accionLabel,this.sectorTarget)
        .subscribe((resp:any)=>{
          this.cargarSectores();
          this.accion='';
          this.close('');
          swal.fire({
            //position: 'top',
            type: 'success',
            title: `Registro Guardado Exitosamente!!`,
            showConfirmButton: false,
            timer: 1500
          })
        });  
    }
  
  

    eliminar(row:any){
      swal.fire({
        title: 'Confirmación',
        text: "Desea eliminar este registro?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.value) {
        this._sectorService.crud('D',row)
            .subscribe((resp:any) => {
              this.cargarSectores();
              swal.fire(`Registro Eliminado!!`)
        });        
      }
    });
    
  }
  

  open(content,datos) {
   
    this.reset(datos);
    this.modalService.open(content, { size: 'lg' });
      
}

 close(content) {
    this.modalService.dismissAll(content);
  }

//---------------------------------------------------------//
//---------- METODOS DE REACTIVE FORMS --------------------//
//---------------------------------------------------------//
  // convenience getter for easy access to form fields
  get controles() { return this.formularioSectores.controls; }

  reset(sector:any){
    this.formularioSectores.reset();
    if(sector === '{}'){
      this.accion='ingresar';
      this.formularioSectores.reset();
  
    }else{
      this.accion='actualizar';
      this.cargarFormReactive(sector); 
    }
  }
  
  crearFormReactive(){
    this.formularioSectores = this.formBuilder.group({
      pk_sectorpla: [0,],
      nombre_sectorpla: [null, { validators: [Validators.required], }],
      cospb_sectorpla: [null, { validators: [Validators.required],  }],
      costotal_sectorpla: [null, { validators: [Validators.required],  }],
      ret_frontal_sectorpla: [null, { validators: [Validators.required],  }],
      ret_posterior_sectorpla: [null, { validators: [Validators.required],  }],
      ret_izq_sectorpla: [null, { validators: [Validators.required],  }],
      ret_der_sectorpla: [null, { validators: [Validators.required],  }],
    });
  }

  
  cargarFormReactive(sector:SectoresModel){
    this.formularioSectores.controls['pk_sectorpla'].setValue(sector.pk_sectorpla);
    this.formularioSectores.controls['nombre_sectorpla'].setValue(sector.nombre_sectorpla);
    
    this.formularioSectores.controls['cospb_sectorpla'].setValue(sector.cospb_sectorpla);
    this.formularioSectores.controls['costotal_sectorpla'].setValue(sector.costotal_sectorpla);
    this.formularioSectores.controls['ret_frontal_sectorpla'].setValue(sector.ret_frontal_sectorpla);
    this.formularioSectores.controls['ret_posterior_sectorpla'].setValue(sector.ret_posterior_sectorpla);
    this.formularioSectores.controls['ret_izq_sectorpla'].setValue(sector.ret_izq_sectorpla);
    this.formularioSectores.controls['ret_der_sectorpla'].setValue(sector.ret_der_sectorpla);
    
  }

  passDataFormBuiderToTarget(){
    if(this.accion === 'ingresar'){
      this.sectorTarget.pk_sectorpla=0;
      this.sectorTarget.fk_ubigeo=this.ciudad;
    } 
    else {
      this.sectorTarget.pk_sectorpla=this.formularioSectores.get('pk_sectorpla').value;
      this.sectorTarget.fk_ubigeo=this.ciudad;
    }

    this.sectorTarget.nombre_sectorpla=this.formularioSectores.get('nombre_sectorpla').value;
    this.sectorTarget.cospb_sectorpla=this.formularioSectores.get('cospb_sectorpla').value;
    this.sectorTarget.costotal_sectorpla=this.formularioSectores.get('costotal_sectorpla').value;
    this.sectorTarget.ret_frontal_sectorpla=this.formularioSectores.get('ret_frontal_sectorpla').value;
    this.sectorTarget.ret_posterior_sectorpla=this.formularioSectores.get('ret_posterior_sectorpla').value;
    this.sectorTarget.ret_izq_sectorpla=this.formularioSectores.get('ret_izq_sectorpla').value;
    this.sectorTarget.ret_der_sectorpla=this.formularioSectores.get('ret_der_sectorpla').value;
  }


}
