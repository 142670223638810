import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LotesService } from '../../services/gplan_calculos/lotes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { ManzanasService } from '../../services/gplan_calculos/manzanas.service';
import { GeografiaService } from '../../services/geografia.service';
import { SectorizacionService } from '../../services/gplan_calculos/sectorizacion.service';
import { LotesModel, PisosModel } from '../../models/calculos.models';
import { PisosService } from '../../services/gplan_calculos/pisos.service';
import { ReportesExcelService } from '../../services/gplan_calculos/reportes-excel.service';

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styles: []
})
export class LotesComponent implements OnInit {
    //necesario colocar estas variables para la paginacion
  p: number = 1;
  filter:any;
  p2: number = 1;
  filter2:any;
  cargando_tabla:boolean=true;
  cargando_pisos:boolean=true;
  mostrarPiso:boolean=true;

   loteTarget:LotesModel={
    pk_lote:0,
    fk_man:null,
    fk_sectorpla:null,
    num_pisos_lote:null,
    vacio_lote:null,
    m2_total_lote:null,
    m2_contruccion_lote:null,
    m2_vacante_lote:null,
    cod_lote:null,
    ret_frontal_lote:null,
    ret_lateral_lote:null,
    ret_posterior_lote:null,
    area_vivienda_pisos_lot:null
  }

  pisoTarget:PisosModel={
    pk_piso:0,
    fk_lote:null,
    num_piso:null,
    area_piso:null,
    orden_piso:null,
  }

  pais:any=null;
  provincia:any=null;
  ciudad:any=null;
  parroquia:any=null;
  manzana:any=null;
  tipo:string='N';

  manzanaTarget:any={};

  listaNaciones:any[]=[];
  listaProvincias:any[]=[];
  listaCiudades:any[]=[];
  listaParroquias:any[]=[];
  listaLotes:any[]=[];
  listaPisos:any[]=[];
  listaManzanas:any[]=[];
  listaSectores:any[]=[];
  accion='ingresar';
  accion2='ingresar';

  v1_area_edificada_total:any=0;
  v2_area_edificable_pb:any=0;
  v3_nivel_ocupacion:any=0;
  v4_area_edificable_total:any=0;
  cosPB:any=0;
  cosTotal:any=0;
  
  public formularioLotes:FormGroup;
  public formularioPisos:FormGroup;
  public valoresInicialesForm:any;
  public valoresInicialesFormPisos:any;

  constructor(public _lotesService:LotesService,
    public _manzanaService:ManzanasService,
    public _geografiaService:GeografiaService,
    public _sectoresService:SectorizacionService,
    public _pisosService:PisosService,
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private modalService: NgbModal,
    private configuracionModal: NgbModalConfig,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private _reporteService:ReportesExcelService
    ) {
      this.configuracionModal.backdrop = 'static';
      this.configuracionModal.keyboard = false;
      
      }

    ngOnInit() {
      this.crearFormReactive();
       this.crearFormReactive2();
      this.valoresInicialesForm=this.formularioLotes.value;
      this.valoresInicialesFormPisos=this.formularioPisos.value;
      this.inicializar();
    }
  
    inicializar(){
      this.cargarPaises();
      
    }

    cargarSectores(){
      this._sectoresService.cargarDatos(this.ciudad)
          .subscribe((sectores:any)=>{
            this.listaSectores=Object.values(sectores);
            
          })
    }

    cargarPaises(){
      this._geografiaService.cargarGeografia(0)
          .subscribe((paises:any)=>{
            this.listaNaciones=Object.values(paises);
            this.cargando_tabla=false;
          })
    }

    selectCOS(cos_seleccionado:any){
      this.cosPB=cos_seleccionado.cospb_sectorpla;
      this.cosTotal=cos_seleccionado.costotal_sectorpla;
      this.calcularInfoTecnica();
      this.formularioLotes.controls['ret_frontal_lote'].setValue(cos_seleccionado.ret_frontal_sectorpla);
      this.formularioLotes.controls['ret_lateral_lote'].setValue(cos_seleccionado.ret_izq_sectorpla);
      this.formularioLotes.controls['ret_posterior_lote'].setValue(cos_seleccionado.ret_posterior_sectorpla);
    }

    cargarProvincias(){
    
      if(this.pais){
        this._geografiaService.cargarGeografia(this.pais)
            .subscribe((provincias:any)=>{
              this.listaProvincias=[];
              this.listaCiudades=[];
              this.listaParroquias=[];
              this.listaManzanas=[];
              this.listaLotes=[];
              this.listaSectores=[];
              this.provincia=this.ciudad=this.parroquia=this.manzana=null;
              this.listaProvincias=Object.values(provincias);
              this.cargando_tabla=false;
            })
        }
        else{
          swal.fire(
            'Faltan Datos',
            'Debe seleccionar pais o el pais seleccioando no contiene datos.',
            'warning'
          )
        }
    }
  
    cargarCiudades(){
      
      if(this.provincia){
      this._geografiaService.cargarGeografia(this.provincia)
          .subscribe((ciudades:any)=>{
            this.listaCiudades=[];
            this.listaParroquias=[];
            this.listaManzanas=[];
            this.listaLotes=[];
            this.listaSectores=[];
            this.ciudad=this.parroquia=this.manzana=null;
            this.listaCiudades=Object.values(ciudades);
            this.cargando_tabla=false;
          })
        }
        else{
          swal.fire(
            'Faltan Datos',
            'Debe seleccionar provincia o el provincia seleccioando no contiene datos.',
            'warning'
          )
        }
    }
  
    cargarParroquias(){
      if(this.ciudad){
      this.cargarSectores();
      this._geografiaService.cargarGeografia(this.ciudad)
          .subscribe((parroquias:any)=>{
            this.listaParroquias=[];
            this.listaManzanas=[];
            this.listaLotes=[];
            this.parroquia=this.manzana=null;
            this.listaParroquias=Object.values(parroquias);
            this.cargando_tabla=false;
          })
        }
        else{
          this.cargando_tabla=true;
          this.cargando_tabla=false;
          swal.fire(
            'Faltan Datos',
            'Debe seleccionar ciudad o el ciudad seleccioando no contiene datos.',
            'warning'
          )
        }
    }

    cargarManzanas(){
      this.listaManzanas=[];
      this.manzana=null;
      if(this.parroquia){
        this._manzanaService.cargarDatos(this.parroquia)
        .subscribe((datos:any)=>{    
          this.listaManzanas=Object.values(datos);
          this.listaLotes=[];
        })
      }else{
        this.listaManzanas=[];
        this.listaLotes=[];
      }
    }

    cargarLotes(valor:any){
      this.listaLotes=[];
      this.manzanaTarget=valor;
      if(this.manzana){
        this.cargando_tabla=true;
        this._lotesService.cargarDatos(this.manzana)
        .subscribe((datos:any)=>{      
          this.listaLotes=Object.values(datos);
          this.cargando_tabla=false;
        })

      }else{
        this.listaLotes=[];
        
      }
    }

    cargarPisos(pk_lote){
      
      this.cargando_pisos=true;
      this._pisosService.cargarDatos(pk_lote)
          .subscribe((pisos:any)=>{
            this.formularioLotes.controls['num_pisos_lote'].setValue(this.listaPisos.length);
            this.listaPisos=Object.values(pisos);
            this.cargando_pisos=false;
            this.calcularInfoTecnica();
          })
    }



    guardar(){
      let accionLabel;
      //pasar datos al objeto target desde los controles del form
      //importante tiene que ir luego de la validacion de actualizar o insertar
      this.passDataFormBuiderToTarget();
           
      if(this.accion === 'ingresar'){ 
        accionLabel='I';
        //this.sector.audit_creacion=this._settingsService.getInfoUser();
      }else{ 
        //this.objetivoEstrategicoTarget.audit_modificacion=this._settingsService.getInfoUser();
        accionLabel='U';
      }
      //console.log(JSON.stringify(this.loteTarget));
    
      this._lotesService.crud(accionLabel,this.loteTarget)
        .subscribe((resp:any)=>{
          this.cargarLotes(this.manzanaTarget);
          this.accion='';
          this.modalService.dismissAll('');
          this.close('Close click');
          swal.fire({
            //position: 'top',
            type: 'success',
            title: `Registro Guardado Exitosamente!!`,
            showConfirmButton: false,
            timer: 1500
          })
        });  
    }
    
    modalRef: NgbModalRef;

    guardar2(){
      let accionLabel;
      //pasar datos al objeto target desde los controles del form
      //importante tiene que ir luego de la validacion de actualizar o insertar
      this.passDataFormBuiderToTarget2();
     
      if(this.accion2 === 'ingresar'){ 
        accionLabel='I';
        //this.sector.audit_creacion=this._settingsService.getInfoUser();
      }else{ 
        //this.objetivoEstrategicoTarget.audit_modificacion=this._settingsService.getInfoUser();
        accionLabel='U';
      }
    
      this._pisosService.crud(accionLabel,this.pisoTarget)
        .subscribe((resp:any)=>{
          
          this.cargarPisos(this.formularioLotes.get('pk_lote').value);
          this.accion2='';
          this.close('Close click');
          swal.fire({
            //position: 'top',
            type: 'success',
            title: `Registro Guardado Exitosamente!!`,
            showConfirmButton: false,
            timer: 1500
          })
        });  
    }

    eliminar(row:any){
      swal.fire({
        title: 'Confirmación',
        text: "Desea eliminar este registro?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.value) {
        this._lotesService.crud('D',row)
            .subscribe((resp:any) => {
              this.cargarLotes(this.manzana);
              swal.fire(`Registro Eliminado!!`)
        });        
      }
    });
    
  }

    eliminar2(row:any){
      swal.fire({
        title: 'Confirmación',
        text: "Desea eliminar este registro?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:'Cancelar',
        confirmButtonText: 'Eliminar'
    }).then((result) => {
      if (result.value) {
        this._pisosService.crud('D',row)
            .subscribe((resp:any) => {
              this.cargarPisos(this.formularioLotes.get('pk_lote').value);
              swal.fire(`Registro Eliminado!!`)
        });        
      }
    });
    
  }

  calcularInfoTecnica(){
    let aux=0;
    let pb_m2=0;
    let total= this.formularioLotes.get('m2_total_lote').value;
    for (let i=0; i<this.listaPisos.length;i++){
      aux=aux+ Number.parseFloat(this.listaPisos[i].area_piso);
      if(this.listaPisos[i].num_piso === 'PB') {
        pb_m2=Number.parseFloat(this.listaPisos[i].area_piso);
        //console.log(`El COS PB ES: ${pb_m2}`);
      }
    }
    this.v1_area_edificada_total=aux;
    this.v2_area_edificable_pb=(total*this.cosPB)/100;
    if(this.v2_area_edificable_pb === 0) {
      this.v3_nivel_ocupacion=0;
      this.v4_area_edificable_total=0;
      
    }else{
      this.v3_nivel_ocupacion=(pb_m2/this.v2_area_edificable_pb)*100;
    }
    
    this.v4_area_edificable_total=total*(this.cosTotal/100);

    //pasar a 2 decimales
    this.v1_area_edificada_total= this.v1_area_edificada_total.toFixed(2);
    this.v2_area_edificable_pb= this.v2_area_edificable_pb.toFixed(2);
    this.v3_nivel_ocupacion= this.v3_nivel_ocupacion.toFixed(2);
    this.v4_area_edificable_total= this.v4_area_edificable_total.toFixed(2);
  }

  
  calculoVacante(){
    let total= this.formularioLotes.get('m2_total_lote').value;
    let construccion= this.formularioLotes.get('m2_contruccion_lote').value;
    
    if(construccion > total)
    {
      swal.fire(
        'Atención',
        'El área de contrucción no debe ser mayor que el área total',
        'warning'
      );
      this.formularioLotes.controls['m2_contruccion_lote'].setValue(null);
    } else{
      let vacante= total-construccion;
      this.formularioLotes.controls['m2_vacante_lote'].setValue(vacante);
    }
      
    
  }

  validarVivienda(){
    let vivienda= this.formularioLotes.get('area_vivienda_pisos_lot').value;
    let total= this.formularioLotes.get('m2_total_lote').value;
    if(vivienda > total)
    {
      swal.fire(
        'Atención',
        'El área de vivienda no puede ser mayor que el área total',
        'warning'
      );
      this.formularioLotes.controls['area_vivienda_pisos_lot'].setValue(null);
      return;
    }
  }

  validarTotalm2(){
    let vacio= this.formularioLotes.get('vacio_lote').value
    if(vacio){
      this.formularioLotes.controls['m2_vacante_lote'].setValue(this.formularioLotes.get('m2_total_lote').value);
    }
    this.calcularInfoTecnica();
    this.validarVivienda();
  }

    open2(content,pisos) {     
      this.reset2(pisos);
      this.modalRef=  this.modalService.open(content, { size: 'sm' });
      this.modalRef.result.then((result) => {
        //console.log( `Closed with: ${result}`);
      }, (reason) => {
        //console.log( `Dismiss with: ${reason}`);
      });
           
    }
  
    
    open(content,datos) {       
        this.reset(datos);
        this.cosPB=datos.cospb_sectorpla;
        this.cosTotal=datos.costotal_sectorpla;
        this.calcularInfoTecnica();
        this.modalService.open(content, { size: 'lg' });          
    }
    
     close(content) {
       //this.modalService.dismissAll(content);
       //console.log('El modal activo es: '+this. activeModal.dismiss());
       //this.activeModal.close(true);
       this.modalRef.close();
      }

      
      reporteXls(parametro){
        if(parametro === 1){
         //1 todos
         this._reporteService.cargarReporteLotes(0,'t').subscribe((datos:any) => {
           this._reporteService.reporteExcelLotesTodos(datos.lotes,datos.gad);
         });
        }else{
         //2 por ubicacion geografica
         if(this.listaLotes.length <= 0){
           swal.fire({
             //position: 'top',
             type: 'error',
             title: `No existen registros del cual sacar reportes.`,
             showConfirmButton: false,
             timer: 1500
           })
         }else{
           this._reporteService.cargarReporteLotes(this.manzana,'m').subscribe((datos:any) => {             
             this._reporteService.reporteExcelLotesManzana(datos.lotes,datos.gad,datos.manzana);
           });  
         }
        }

      }

  //---------------------------------------------------------//
  //---------- METODOS DE REACTIVE FORMS --------------------//
  //---------------------------------------------------------//
  // convenience getter for easy access to form fields
  get controles() { return this.formularioLotes.controls; }
  get controles2() { return this.formularioPisos.controls; }

  reset(lotes:any){
    this.formularioLotes.reset(this.valoresInicialesForm);
    if(lotes === '{}'){
      this.accion='ingresar';
      this.mostrarPiso=false;
    }else{
      this.accion='actualizar';
      this.mostrarPiso=true;
      this.cargarPisos(lotes.pk_lote);
      this.cargarFormReactive(lotes); 
    }
    this.cambioLoteVacio();
  }

  reset2(pisos:any){
    this.formularioPisos.reset(this.valoresInicialesFormPisos);
    if(pisos === '{}'){
      this.accion2='ingresar';
      let piso='PB';
      let orden=0;
      let cantidadPisos= this.listaPisos.length;
      if(cantidadPisos > 0){
        orden=cantidadPisos;
        piso=orden.toString();
      }
      let lot=this.formularioLotes.get('pk_lote').value;
      this.formularioPisos.controls['fk_lote'].setValue(lot);
      this.formularioPisos.controls['num_piso'].setValue(piso);
      this.formularioPisos.controls['orden_piso'].setValue(orden);
      
      
    }else{
      this.accion2='actualizar';
      this.cargarFormReactive2(pisos); 
    }
    
  }
  
  crearFormReactive2(){
   
    this.formularioPisos = this.formBuilder.group({
      pk_piso:[0, { validators: [Validators.required], }],
      fk_lote:[0, { validators: [Validators.required], }],
      num_piso:[null, { validators: [Validators.required], }],
      area_piso:[null, { validators: [Validators.required], }],
      orden_piso:[false, { validators: [Validators.required], }],
    });
  }

  crearFormReactive(){
   
    this.formularioLotes = this.formBuilder.group({
      pk_lote:[0, { validators: [Validators.required], }],
      fk_man:[0, ],
      fk_sectorpla:[null, { validators: [Validators.required], }],
      num_pisos_lote:[0, { validators: [Validators.required], }],
      vacio_lote:[false, { validators: [Validators.required], }],
      m2_total_lote:[null, { validators: [Validators.required], }],
      m2_contruccion_lote:[null, { validators: [Validators.required], }],
      m2_vacante_lote:[null, { validators: [Validators.required], }],
      cod_lote:[null, { validators: [Validators.required], }],
      ret_frontal_lote:[null, { validators: [Validators.required], }],
      ret_lateral_lote:[null, { validators: [Validators.required], }],
      ret_posterior_lote:[null, { validators: [Validators.required], }],
      area_vivienda_pisos_lot:[null, { validators: [Validators.required], }],
    });
  }


  cambioLoteVacio(){
    let esVacio=this.formularioLotes.get('vacio_lote').value;
    if(esVacio){
     
      swal.fire(
        'Atención',
        'Considere que si el lote es o cambia a "vacio", si continene información de pisos, esta será eliminada al momento de guardar el registro.',
        'warning'
      )
      this.formularioLotes.controls['num_pisos_lote'].setValue(0);
      this.formularioLotes.controls['m2_contruccion_lote'].setValue(0);
      this.formularioLotes.controls['m2_contruccion_lote'].disable();
      this.formularioLotes.controls['area_vivienda_pisos_lot'].setValue(0);
      this.formularioLotes.controls['area_vivienda_pisos_lot'].disable();
      this.formularioLotes.controls['m2_vacante_lote'].setValue(this.formularioLotes.get('m2_total_lote').value);
    }else{
      this.formularioLotes.controls['m2_contruccion_lote'].enable();
      this.formularioLotes.controls['area_vivienda_pisos_lot'].enable();
    }
  }
  
  cargarFormReactive(lote:LotesModel){
    this.formularioLotes.controls['pk_lote'].setValue(lote.pk_lote);
    this.formularioLotes.controls['fk_man'].setValue(lote.fk_man);
    this.formularioLotes.controls['fk_sectorpla'].setValue(lote.fk_sectorpla);
    this.formularioLotes.controls['num_pisos_lote'].setValue(lote.num_pisos_lote);
    this.formularioLotes.controls['vacio_lote'].setValue(lote.vacio_lote);
    this.formularioLotes.controls['m2_total_lote'].setValue(lote.m2_total_lote);
    this.formularioLotes.controls['m2_contruccion_lote'].setValue(lote.m2_contruccion_lote);
    this.formularioLotes.controls['m2_vacante_lote'].setValue(lote.m2_vacante_lote);
    this.formularioLotes.controls['cod_lote'].setValue(lote.cod_lote);
    this.formularioLotes.controls['ret_frontal_lote'].setValue(lote.ret_frontal_lote);
    this.formularioLotes.controls['ret_lateral_lote'].setValue(lote.ret_lateral_lote);
    this.formularioLotes.controls['ret_posterior_lote'].setValue(lote.ret_posterior_lote);
    this.formularioLotes.controls['area_vivienda_pisos_lot'].setValue(lote.area_vivienda_pisos_lot);
  }

  cargarFormReactive2(piso:PisosModel){
    this.formularioPisos.controls['pk_piso'].setValue(piso.pk_piso);
    this.formularioPisos.controls['fk_lote'].setValue(piso.fk_lote);
    this.formularioPisos.controls['num_piso'].setValue(piso.num_piso);
    this.formularioPisos.controls['area_piso'].setValue(piso.area_piso);
    this.formularioPisos.controls['orden_piso'].setValue(piso.orden_piso);
  }

  passDataFormBuiderToTarget(){
    if(this.accion === 'ingresar'){
      this.loteTarget.pk_lote=0;
      this.loteTarget.fk_man=this.manzana;
    } 
    else {
      this.loteTarget.pk_lote=this.formularioLotes.get('pk_lote').value;
      this.loteTarget.fk_man=this.manzana;
    }
    this.loteTarget.fk_sectorpla=this.formularioLotes.get('fk_sectorpla').value;
    this.loteTarget.num_pisos_lote=this.formularioLotes.get('num_pisos_lote').value;
    this.loteTarget.vacio_lote=this.formularioLotes.get('vacio_lote').value;
    this.loteTarget.m2_total_lote=this.formularioLotes.get('m2_total_lote').value;
    this.loteTarget.m2_contruccion_lote=this.formularioLotes.get('m2_contruccion_lote').value;
    this.loteTarget.m2_vacante_lote=this.formularioLotes.get('m2_vacante_lote').value;
    this.loteTarget.cod_lote=this.formularioLotes.get('cod_lote').value;
    this.loteTarget.ret_frontal_lote=this.formularioLotes.get('ret_frontal_lote').value;
    this.loteTarget.ret_lateral_lote=this.formularioLotes.get('ret_lateral_lote').value;
    this.loteTarget.ret_posterior_lote=this.formularioLotes.get('ret_posterior_lote').value;
    this.loteTarget.area_vivienda_pisos_lot=this.formularioLotes.get('area_vivienda_pisos_lot').value;
    
  
  }

  
  passDataFormBuiderToTarget2(){
    if(this.accion2 === 'ingresar'){
      this.pisoTarget.pk_piso=0;
      this.pisoTarget.fk_lote=this.formularioPisos.get('fk_lote').value;
    } 
    else {
      this.pisoTarget.pk_piso=this.formularioPisos.get('pk_piso').value;
      this.pisoTarget.fk_lote=this.formularioPisos.get('fk_lote').value;
    }
    this.pisoTarget.num_piso=this.formularioPisos.get('num_piso').value;
    this.pisoTarget.area_piso=this.formularioPisos.get('area_piso').value;
    this.pisoTarget.orden_piso=this.formularioPisos.get('orden_piso').value; 
  }


}
